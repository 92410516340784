import { css } from '@emotion/core';

const sizes = {
  phone: 576,
  tablet: 768,
  desktop: 992,
  wide: 1200
};

//
// ─── MEDIA QUERIES ──────────────────────────────────────────────────────────────
//

const minWidth = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (min-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const maxWidth = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── GRID ───────────────────────────────────────────────────────────────────────
//

// const breakpoints = ['576px', '768px', '992px', '1200px'];
const breakpoints = Object.values(sizes).reduce((acc, cur, i) => {
  acc[i] = `${cur}px`;
  return acc;
}, []);

const containerWidths = ['100%', 540, 720, 960, 1200];
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];
const grid = { breakpoints, containerWidths };

// ────────────────────────────────────────────────────────────────────────────────

export default {
  gap: '64px', // The container horizontal gap, which acts as the offset for breakpoints
  space,
  breakpoints,
  containerWidths,
  grid,
  minWidth,
  maxWidth
};
