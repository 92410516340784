import React, { useState, useEffect } from 'react';
import ThemeContext from './createContext';

const Provider = ({ children }) => {
  const [themeStyle, setThemeStyle] = useState('light');
  const toggleTheme = () => {
    if (themeStyle === 'light') {
      localStorage.setItem('themeStyle', 'dark');
      setThemeStyle('dark');
    } else {
      localStorage.setItem('themeStyle', 'light');
      setThemeStyle('light');
    }
  };

  useEffect(() => {
    const localThemeStyle = localStorage.getItem('themeStyle');
    if (localThemeStyle) {
      setThemeStyle(localThemeStyle);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        themeStyle,
        toggleTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default Provider;
