/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* eslint-disable */
import withProvider from './src/store/withProvider';
// import wrapPageElementWithTransition from './src/helpers/wrapPageElement';

export const wrapRootElement = withProvider;

// Conflict with modal
// Page Transitions
// export const wrapPageElement = wrapPageElementWithTransition;
