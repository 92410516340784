import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import ThemeContext from './createContext';
import Provider from './provider';
import theme from '../theme';
import { colorThemes } from '../theme/utils/colors';

const { DARK } = colorThemes;

const propTypes = {
  element: PropTypes.node.isRequired
};

const withProvider = ({ element }) => (
  <Provider>
    <ThemeContext.Consumer>
      {({ themeStyle }) => {
        const adjustedTheme = ancestorTheme =>
          themeStyle === 'dark'
            ? { ...ancestorTheme, ...DARK }
            : { ...ancestorTheme };
        return (
          <ThemeProvider theme={theme}>
            <ThemeProvider theme={adjustedTheme}>{element}</ThemeProvider>
          </ThemeProvider>
        );
      }}
    </ThemeContext.Consumer>
  </Provider>
);

withProvider.propTypes = propTypes;

export default withProvider;
