export default {
  easing: 'ease-out',
  radiusSmall: '2px',
  radius: '4px',
  radiusLarge: '6px',
  radiusRounded: '290486px',
  speed: '86ms',
  headerHeight: '3.625rem',
  navbarHeight: '3em'
};
